import { toKebabCase } from "js-convert-case";
import { App } from "vue";
import SpinLoader from "@rebolet/design-system/lib/components/atoms/spin-loader/Index.vue";
import Row from "@rebolet/design-system/lib/components/atoms/row/Index.vue";
import Col from "@rebolet/design-system/lib/components/atoms/column/Index.vue";
import Popover from "@rebolet/design-system/lib/components/atoms/popover/Index.vue";

export default function componentsImporter(app: App) {
  [SpinLoader, Row, Col, Popover].map((c) => {
    app.component(toKebabCase(c.name), c);
  });
}
