
import { ConfigProvider } from "ant-design-vue";
import App from "./App.vue";
export default {
  name: "SuspensedApp",
  components: {
    ConfigProvider,
    App,
  },
};
