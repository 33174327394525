import { RouteRecordRaw } from "vue-router";

export default function routeProvider(): Array<RouteRecordRaw> {
  return [
    {
      path: "/login",
      name: "login",
      component: () => import(/* webpackChunkName: "login" */ "@/modules/auth/views/pages/SignIn.vue"),
    },
  ];
}
