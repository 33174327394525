import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { authGuard } from "@auth0/auth0-vue";
const routes = routeProvider();
const admin_id = "";
const router = createRouter({
  history: createWebHistory(),
  routes,
});
export default function routeProvider(): Array<RouteRecordRaw> {
  return [
    {
      path: "/welcome",
      component: () => import(/* webpackChunkName: "console" */ "@/modules/console/views/template/ConsoleTemplate.vue"),
      children: [
        {
          path: "",
          name: "serial",
          component: () => import(/* webpackChunkName: "/testing" */ "@/modules/console/views/pages/welcome/Index.vue"),
        },
        {
          path: "/grading",
          name: "grading",
          component: () =>
            import(/* webpackChunkName: "/supervisor" */ "@/modules/console/views/pages/grading/Index.vue"),
        },
      ],
      beforeEnter: () => true,
    },
    {
      path: "/supervisor",
      name: "supervisor",
      component: () =>
        import(/* webpackChunkName: "/supervisor" */ "@/modules/console/views/pages/supervisor/Index.vue"),
    },
    {
      path: "/admin",
      name: "admin",
      component: () => import(/* webpackChunkName: "admin" */ "@/modules/console/views/pages/admin/Index.vue"),
      meta: { Auth: true },
      beforeEnter: (to, form, next) => {
        if (admin_id == null) {
          next("/login"); // go to '/login';
        } else {
          next(); // allow to enter route
        }
      },
    },
  ];
}
