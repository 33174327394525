import { App } from "vue";
import { createRouter as createVueRouter, createWebHistory, Router, RouteRecordRaw } from "vue-router";
import { createAuthGuard } from "@auth0/auth0-vue";

const routeRegister: Array<RouteRecordRaw> = [];

export function createRouteRegistrar() {
  return (routes: Array<RouteRecordRaw>) => {
    routeRegister.push(...routes);
  };
}

export const routeRegistrar = createRouteRegistrar();

export default function createRouter(app: App): Router {
  routeRegistrar([
    {
      path: "/",
      component: () => import(/* webpackChunkName: "landing" */ "@/root/views/templates/IndexTemplate.vue"),
    },
  ]);

  const routes: Array<RouteRecordRaw> = [...routeRegister];

  for (const route of routes) {
    if (route.beforeEnter) {
      route.beforeEnter = createAuthGuard(app);
    }
  }

  const router = createVueRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkExactActiveClass: "active",
    routes,
  });

  return router;
}
