import { App } from "vue";
import I18NextVue from "i18next-vue";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const isProduction = process.env.VUE_APP_ENVIROMENT === "production";

const locizeOptions = {
  projectId: process.env.VUE_APP_LOCIZE_PROJECTID,
  apiKey: process.env.VUE_APP_LOCIZE_APIKEY,
  version: process.env.VUE_APP_LOCIZE_VERSION,
};

export const i18nextPromise = i18next.use(LanguageDetector).init({
  debug: !isProduction,
  fallbackLng: "en",
  saveMissing: !isProduction,
  backend: locizeOptions,
});

export default function (app: App) {
  app.use(I18NextVue, { i18next });
  return app;
}
