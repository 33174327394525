
import { LoadingOutlined } from "@ant-design/icons-vue";
import { Spin } from "ant-design-vue";
import { defineComponent, h } from "vue";

export default defineComponent({
  name: "CzSpinLoader",
  components: {
    Spin,
  },
  setup() {
    const indicator = h(LoadingOutlined, {
      style: {
        fontSize: "24px",
      },
      spin: true,
    });
    return {
      indicator,
    };
  },
});
