<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { i18nextPromise } from "./i18n";
export default {
  name: "App",
  async setup() {
    await (async (stallTime = 1000) => {
      await new Promise((resolve) => setTimeout(resolve, stallTime));
    })();
    await i18nextPromise;
  },
};
</script>

<style scoped lang="less">
#app {
  height: 100vh;
}
</style>
